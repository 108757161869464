import { createSlice } from '@reduxjs/toolkit';

export interface UserInfo {
	DEPARTMENT: string[];
	TYPE: string[];
	exp: number;
	permissions: string[];
	sub: string;
	userId: string;
	name: string;
	country: string;
	formatName: string;
	firstName: string;
	full_department: string[];
	email: string;
	employeeId: string;
	userInitials: string;
}

interface UserState {
	userInfo?: UserInfo;
	language: string;
	tempLanguage: string;
	customerCountryCode?: string;
}

const initialState: UserState = { language: 'EN', customerCountryCode: '', tempLanguage: '' };

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateStateUser: (states: any, action: { payload: Partial<UserState> }) => {
			return (states = { ...states, ...action.payload });
		},
		setLang(state: UserState, action) {
			state.language = action.payload;
		},
		setTempLanguage(state: UserState, action) {
			state.tempLanguage = action.payload;
		},
		reset: () => initialState,
	},
});

export const { updateStateUser, setLang, setTempLanguage } = userSlice.actions;
export default userSlice.reducer;
