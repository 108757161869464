import { leadAdvSearchService } from '@/services/leadAdvSearch.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useAdvSearchServiceFeedback = () => {
	const queryClient = useQueryClient();

	return useMutation(leadAdvSearchService.submitFeedback, {
		onError: () => {
			console.log('There was an error in useAdvSearchServiceFeedback');
		},
		onSettled: () => {
			queryClient.invalidateQueries();
		},
	});
};

export const useLeadAdvSearchNegFeedbackList = (key: string, langCode: string) => {
	const timeParameters = {
		staleTime: 0 * (60 * 1000),
		cacheTime: 0 * (60 * 1000),
	};
	return useQuery([key], async () => leadAdvSearchService.getNegFeedbackList(langCode), {
		...timeParameters,
		enabled: !!langCode,
	});
};

export const useProductSearch = (key: string | undefined) => {
	const queryClient = useQueryClient();

	const mutationOptions = {
		onError: () => {
			console.log(`There was an error in ${key}`);
		},
		onSettled: () => {
			queryClient.invalidateQueries();
		},
	};
	switch (key) {
		case 'advancedSearch':
			return useMutation(leadAdvSearchService.advancedSearch, mutationOptions);
		case 'hotKeywordSearch':
			return useMutation(leadAdvSearchService.hotKeywordSearch, mutationOptions);

		default:
			throw new Error(`Unsupported key: ${key} in useAdminManagement hook`);
	}
};
