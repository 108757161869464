import {
	LazyLeadAdminPanel,
	LazyLeadStatusReport,
	LazyContentHubViewPage,
	LazyContentHubDetailsPage,
	LazyContentHubPage,
	LazyFormPage,
	LazyHome,
	LazySurveyHubPage,
	LazyTranslationalRequest,
	LazyMasteryChallenge,
	LazyMasteryChallengeCampaignDetails,
	LazyTrainingHubViewPage,
	LazyTrainingHubDetailsPage,
	LazyTrainingHubPage,
} from './pages';

export const ApplayoutRoutes = [
	{
		path: '/',
		element: <LazyHome />,
	},
	{
		path: '/contenthub',
		element: <LazyContentHubPage />,
		children: [
			{
				path: ':category',
				element: <LazyContentHubPage />,
				children: [
					{
						path: ':fileName',
						element: <LazyContentHubViewPage />,
					},
				],
			},
			{
				path: ':category/:subCategory',
				element: <LazyContentHubPage />,
				children: [
					{
						path: ':fileName',
						element: <LazyContentHubViewPage />,
					},
				],
			},
		],
	},
	{
		path: '/survey',
		element: <LazySurveyHubPage />,
	},
	{
		path: '/form',
		element: <LazyFormPage />,
	},
	{
		path: '/home',
		element: <LazyHome />,
	},
	{
		path: '/leadreport',
		element: <LazyLeadStatusReport />,
	},
	{
		path: '/admin',
		element: <LazyLeadAdminPanel />,
	},
	{
		path: '/translationrequest',
		element: <LazyTranslationalRequest />,
	},
	{
		path: '/masterychallenge',
		element: <LazyMasteryChallenge />,
		children: [
			{
				path: 'terms',
				element: <LazyMasteryChallengeCampaignDetails />,
			},
		],
	},
	{
		path: '/traininghub',
		element: <LazyTrainingHubPage />,
		children: [
			{
				path: ':category',
				element: <LazyTrainingHubPage />,
			},
			{
				path: ':category/:subCategory',
				element: <LazyTrainingHubPage />,
				children: [
					{
						path: ':fileName',
						element: <LazyTrainingHubViewPage />,
					},
				],
			},
		],
	},
];
