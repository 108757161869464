import icons from '@/assets/icons';
import { Button } from 'antd-mobile';
import React from 'react';

interface HotKeywordSearchResult {
	product_division: string;
	product_family_name: string;
}

interface RecommendedProductProps {
	hotKeywordSearchResult: HotKeywordSearchResult;
	handleSelectButtonClick: (result: HotKeywordSearchResult) => void;
	setIsRecommendedProdFamilySelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecommendedProduct: React.FC<RecommendedProductProps> = (props) => {
	const { hotKeywordSearchResult, handleSelectButtonClick, setIsRecommendedProdFamilySelected } =
		props;
	const selectBtn = 'Select';

	return (
		<div className='bg-[#FFEEEE] border-[2px] border-primary border-solid flex flex-col px-4 py-2 mb-2 rounded-lg gap-2'>
			<div className='flex justify-between'>
				<div className='flex flex-row gap-2 items-center'>
					<img src={icons.recommendedIcon} className='w-6 h-6' alt='recommended' />
					<span className='text-primary font-semibold text-[16px]'>Recommended</span>
				</div>
				<div className='flex items-center'>
					<Button
						className='text-primary border-primary bg-[#FFEEEE] py-1'
						color='default'
						shape='rounded'
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							e.preventDefault();
							setIsRecommendedProdFamilySelected && setIsRecommendedProdFamilySelected(true);
							handleSelectButtonClick(hotKeywordSearchResult);
						}}
					>
						{selectBtn}
					</Button>
				</div>
			</div>
			<div className='bg-white p-2 rounded-lg'>
				<span className='uppercase'>{hotKeywordSearchResult?.product_division} | </span>
				<span className='uppercase'>{hotKeywordSearchResult?.product_family_name}</span>
			</div>
		</div>
	);
};

export default RecommendedProduct;
