import { ResponseBaseType, http } from '@/core/services/http.service';
import { baseUrl } from './index';

class LeadAdvSearchService {
	submitFeedback = async ({ requestBody, options }: any) => {
		try {
			const response: any = await http.post(`${baseUrl}/leads/productSearchFeedback`, requestBody, {
				headers: {
					NotshowCustomLoading: false,
					customLoadingText: 'Submitting Feedback...',
					...options,
				},
			});
			return response;
		} catch (err) {
			console.log('Error in contentTranslation', err);
		}
	};

	getNegFeedbackList = async (languageCode: string): Promise<any> => {
		try {
			const response: any = await http.get<ResponseBaseType<any>>(
				`${baseUrl}/leads/dislikeReasons`,
				{
					params: { languageCode },
					headers: {
						NotshowCustomLoading: true,
					},
				}
			);
			return response?.leadProdSearchFeedbackReasonList || [];
		} catch (err) {
			console.log('Error in getNegFeedbackList', err);
		}
	};

	advancedSearch = async ({ requestBody, countryCode }: any) => {
		try {
			const response: any = await http.get(`${baseUrl}/product/search`, {
				//const response: any = await http.get('http://127.0.0.1:5001/search', {
				params: requestBody,
				headers: {
					NotshowCustomLoading: true,
					countryCode,
				},
			});
			return response;
		} catch (err) {
			console.log('Error in advancedSearch', err);
		}
	};
	hotKeywordSearch = async ({ requestBody, countryCode }: any) => {
		try {
			const response: any = await http.get(`${baseUrl}/product/search-hot-keywords`, {
			//const response: any = await http.get('http://127.0.0.1:5001/search-hot-keywords', {
				params: requestBody,
				headers: {
					NotshowCustomLoading: true,
					countryCode,
				},
			});
			return response;
		} catch (err) {
			console.log('Error in hotKeywordSearch', err);
		}
	};
}

export const leadAdvSearchService = new LeadAdvSearchService();
