import { useCallback } from 'react';

const debounce = (func: any, delay: number) => {
	let timeout: any;
	return (...args: any) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), delay);
	};
};

const useDebounceHook = (callback: any, delay: number) => {
	return useCallback(
		debounce((inputVal: string) => callback(inputVal), delay),
		[]
	);
};

export default useDebounceHook;
