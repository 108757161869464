import { http, ResponseBaseType } from '@/core/services/http.service';
import { baseUrl } from './index';
import jwt_decode from 'jwt-decode';
import { deleteCookie, setCookie } from '@/core/utils';

class UserService {
	getUserInfo = async (code: any) => {
		try {
			if (!this.hasTimeExpired()) {
				const token = localStorage.getItem('tokenOneAPJChampionLeads') || 'null';
				const tokenArr = JSON.parse(token);
				//console.log(tokenArr);

				if (tokenArr && tokenArr.length > 1) {
					const [accessToken, idToken] = tokenArr;
					//console.log(accessToken, idToken);
					const decodedAccessToken = jwt_decode(accessToken);
					const decodedIdToken = jwt_decode(idToken);
					return [decodedAccessToken, decodedIdToken];
				}
			} else {
				localStorage.removeItem('tokenOneAPJChampionLeads');
				localStorage.removeItem('tokenExpiry');
				localStorage.removeItem('langCode');
				deleteCookie('id_token');
				const token = await this.getTokenFromCode(code);
				const pathname = window.location.pathname;
				const leadsPath = '/leads/';
				if (pathname.startsWith(leadsPath) && pathname.length > leadsPath.length) {
					const remainingPath = pathname.slice(leadsPath.length);
					localStorage.setItem('redirectUrl1APJ', remainingPath);
				}
				return token;
			}
		} catch (err) {
			console.log('Error in getUserInfo', err);
		}
	};
	getTokenFromCode = async (code: any): Promise<any> => {
		try {
			const response: any = await http.get<ResponseBaseType<any>>(`${baseUrl}/leads/oauth/token`, {
				headers: {
					NotshowCustomLoading: true,
				},
				params: { code: code },
			});
			if (response?.access_token) {
				//console.log('Token response', response);
				const value = JSON.stringify([response.access_token, response.id_token]);
				const expiry_time = this.setExpiryTime(Number(response.expires_in));
				/* Token information is stored here in local storage instead of cookie as we faced some cookie permission issue, more details in ALS-99 */
				localStorage.setItem('tokenOneAPJChampionLeads', value);
				localStorage.setItem('tokenExpiry', expiry_time);
				deleteCookie('id_token');
				setCookie('id_token', response.id_token, response.expires_in / 86400);

				const decodedAccessToken = jwt_decode(response.access_token);
				const decodedIdToken = jwt_decode(response.id_token);
				return [decodedAccessToken, decodedIdToken];
			}
		} catch (err) {
			console.log('Error in getTokenFromCode', err);
		}
	};
	setExpiryTime = (seconds: number) => {
		const date: Date = new Date();
		date.setSeconds(date.getSeconds() + seconds);
		/* console.log('Expiry date set', date); */
		return JSON.stringify(date);
	};
	hasTimeExpired = () => {
		const dateNow: Date = new Date();
		const dateExpire = new Date(JSON.parse(localStorage.getItem('tokenExpiry') || '0'));
		/* console.log('Date NOW', dateNow);
		console.log('Date EXPIRE', dateExpire); */
		return dateNow > dateExpire ? true : false;
	};
}

export const userService = new UserService();
